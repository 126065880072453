import React from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { Box, Heading, Image, Text } from '@chakra-ui/react';
import { AiOutlineHeart } from 'react-icons/ai';
import useInvitation from '@/hooks/useInvitation';

import loveStoryData from '@/api/love-story';
import WithAnimation from '@/components/Common/WithAnimation';
import { PRIMARY_FONT } from '@/constants/fonts';
import { HEADING_STYLES } from '@/constants/colors';
import { ID_LOVESTORY_SECTION } from '@/constants/identifier';
import txtWording from './locales';
import useLang from '@/hooks/useLang';
import { ENABLE_LOVE_STORY } from '@/constants/feature-flags';
import { useGuest } from '@/context/guest';

function LoveStory() {
  const isInvitation = useInvitation();
  const lang = useLang();
  const { guest } = useGuest();
  const { name } = guest;

  const finalLoveStory = isInvitation
    ? [
        ...loveStoryData,
        {
          img: '',
          title: `26 FEBRUARY 2024`,
          description: `THE BIG DAY! <br />
    And now, we invite you to share and celebrate the next chapter of our story. <br />
    Wonderland Uluwatu, Bali`,
        },
      ]
    : [
        ...loveStoryData,
        {
          img: '',
          title: `26 FEBRUARY 2024`,
          description: `THE BIG DAY! <br />
    And now, we'd like to share and celebrate the next chapter of our story with you!`,
        },
      ];

  const renderIcon = (img) => {
    if (img) {
      return (
        <Image
          src={img}
          boxSize="50px"
          borderRadius="full"
          objectFit="cover"
          objectPosition="center"
        />
      );
    }

    return <AiOutlineHeart color="white" size="18px" />;
  };

  return (
    <Box bgColor="bgPrimary" padding="42px 0 0 0" name={ID_LOVESTORY_SECTION}>
      <Box paddingBottom="42px" color="mainColorText">
        <Text textAlign="center" fontWeight="light" fontFamily="serif">
          {txtWording.textDear[lang]}
        </Text>
        <Text fontSize="xl" textAlign="center" fontFamily="serif" fontWeight="bold">
          {name || `${txtWording.guest[lang]}`}
        </Text>
      </Box>
      {ENABLE_LOVE_STORY && (
        <>
          <Heading {...HEADING_STYLES} textAlign="left" paddingLeft="24px">
            {txtWording.lovestory[lang]}
          </Heading>
          <Box borderRadius="16px" padding="16px">
            <Timeline
              lineColor="#6f8963"
              lineStyle={{ top: '20px', height: 'calc(100% - 60px)', left: '8px' }}
            >
              {finalLoveStory.map((item, index) => {
                return (
                  <WithAnimation key={`index-${index}`}>
                    <TimelineEvent
                      title={item.title}
                      titleStyle={{
                        color: '#352023',
                        fontWeight: 'normal',
                        paddingLeft: '0',
                        fontSize: '16px',
                        fontFamily: `'Noto Serif Display', serif`,
                      }}
                      subtitleStyle={{ color: '#A7938E', fontFamily: PRIMARY_FONT }}
                      cardHeaderStyle={{ color: 'white' }}
                      icon={false && renderIcon(item.img)}
                      // iconStyle={{ width: '50px', height: '50px' }}
                      bubbleStyle={{
                        width: '16px',
                        height: '16px',
                        border: 'none',
                        backgroundColor: '#6f8963',
                      }}
                      contentStyle={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: '0',
                        color: '#A7938E',
                        fontSize: '16px',
                        fontWeight: '400',
                        paddingLeft: '0',
                        marginTop: '8px',
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    </TimelineEvent>
                  </WithAnimation>
                );
              })}
            </Timeline>
          </Box>
        </>
      )}
    </Box>
  );
}

export default LoveStory;
