import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  Text,
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Wishlist from './WishlishCard';

import txtWording from './locales';
import { INPUT_COMMON_PROPS, ALERT, FORM_LABEL_PROPS, ERROR_TYPE } from './types';
import { HEADING_STYLES } from '@/constants/colors';
import { ID_WISH_SECTION } from '@/constants/identifier';
import { useGuest } from '@/context/guest';
import useWishes from '@/usecase/use-wishes';
import useFormFields from '@/hooks/useFormFields';
import useInvitation from '@/hooks/useInvitation';

function WishesSection({ lang }) {
  const [showAlert, setShowAlert] = useState(ALERT);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const { guest, isRegistered } = useGuest();
  const { code, name } = guest;
  const { isLoading, isMutating, data, error, onPostWish, onRefetch } = useWishes();
  const isInvitation = useInvitation();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    wish: '',
  });

  useEffect(() => {
    onResetForm();
  }, [isRegistered]);

  const toast = useToast();

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
        isClosable: true,
      });
    }
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorType(ERROR_TYPE);

    if (!formFields.name || !formFields.wish) {
      setErrorType({
        name: !formFields.name && txtWording.requiredField[lang],
        wish: !formFields.wish && txtWording.requiredField[lang],
      });
      return;
    }

    await onPostWish({
      code,
      name: formFields.name,
      wish: formFields.wish,
      onError: () => {
        handleSetAlert(false);
      },
      onSuccess: () => {
        onResetForm();
        handleSetAlert(true);
        onRefetch();
      },
    });
  };

  const renderAlert = () => {
    if (!showAlert.messageTitle) return null;

    return (
      <Box paddingBottom="0" paddingTop="16px">
        <Alert status={showAlert.success ? 'success' : 'error'} borderRadius="lg">
          <AlertIcon />
          <AlertDescription fontSize="sm">{showAlert.messageContent}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            size="sm"
            onClick={() => setShowAlert(ALERT)}
          />
        </Alert>
      </Box>
    );
  };

  return (
    <Box padding="24px 24px" bgColor="bgPrimary" name={ID_WISH_SECTION}>
      {/* Title & Description sections */}
      <Box textAlign="center" color="mainColorText">
        <WithAnimation>
          <Heading {...HEADING_STYLES} margin="16px 0">
            {txtWording.title[lang]}
          </Heading>
        </WithAnimation>
        <Text>{txtWording.desc[lang]}</Text>
      </Box>
      {renderAlert()}
      {/* Box for FORM */}
      <WithAnimation>
        <Box paddingTop="2">
          <FormControl margin="8px 0" isInvalid={errorType.name}>
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              onChange={createChangeHandler('name')}
              value={formFields.name}
            />
            <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
          </FormControl>
          <FormControl margin="16px 0" isInvalid={errorType.wish}>
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}:</FormLabel>
            <Textarea
              {...INPUT_COMMON_PROPS}
              onChange={createChangeHandler('wish')}
              value={formFields.wish}
            />
            <FormErrorMessage marginTop="4px">{errorType.wish}</FormErrorMessage>
          </FormControl>
          <Box>
            <Button
              isFullWidth
              isLoading={isMutating}
              color="secondaryColorText"
              fontWeight="light"
              colorScheme="green"
              size="md"
              type="button"
              bgColor="bgSecondary"
              textAlign="left"
              fontFamily="body"
              justifyContent="left"
              borderRadius="0"
              marginTop="12px"
              marginBottom="34px"
              onClick={handleSubmit}
            >
              {txtWording.send[lang]}
            </Button>
          </Box>
        </Box>
      </WithAnimation>
      {/* Wishlist Card */}
      {!error && (
        <WithAnimation>
          <Wishlist wishlistData={data} loading={isLoading} />
        </WithAnimation>
      )}
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
